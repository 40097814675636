import { SidebarContext } from "./SidebarContext";
import { PropsWithChildren, useState } from "react";

export type SidebarProviderProps = PropsWithChildren;

export function SidebarProvider(props: SidebarProviderProps) {
  const { children } = props;
  const localIsOpen = localStorage.getItem("navdrawerstateopen") === "true";
  const [isOpen, setIsOpen] = useState(localIsOpen);

  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
}
