import { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./index.scss";

import { AppLayout } from "./components/layouts/AppLayout";
import { HomePage } from "./pages/Home";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/Logout";
import OktaAuth, { getUserData } from "./utils/okta";

import { isProd, isUAT } from "#utils/env";
import { getUserIdByEmail } from "@/utils/getUserIdByEmail";
import { Providers } from "./components/layouts/Providers";
import { identifyUserForAnalytics } from "./scripts/analytics";
import { initDatadogRum } from "./scripts/datadog";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authPromiseRef = useRef<Promise<void> | null>(null);

  useEffect(() => {
    if (!authPromiseRef.current) {
      authPromiseRef.current = new Promise((resolve) => {
        // @ts-expect-error-next-line
        OktaAuth.init(async (authState) => {
          const { isAuthenticated } = authState;
          if (!isAuthenticated) return;
          resolve();

          // @ts-expect-error-next-line
          const { email } = getUserData();
          if (!email) return;

          // Use monitoring tools in UAT and Prod
          const shouldUseMonitoring = isProd() || isUAT();
          if (!shouldUseMonitoring) return;

          // Get user ID by email from UUID generator service
          const userId = await getUserIdByEmail(email);
          if (!userId) return;

          // Initialize Datadog RUM
          initDatadogRum(userId);

          // Identify user for Segment analytics
          identifyUserForAnalytics(userId);
        });
      });

      bootstrap();
    }

    async function bootstrap() {
      if (!OktaAuth.isAuthenticated()) {
        try {
          await authPromiseRef.current;
        } catch (error) {
          console.error("Authentication failed:", error);
          return;
        }
      }
      setIsAuthenticated(true);
    }
  }, []);

  return isAuthenticated ? (
    <Providers>
      <Router>
        <Routes>
          <Route
            path="/login/oidc/callback"
            element={<LoginPage isAuthenticated={true} />}
          />
          <Route path="/logout/oidc/callback" element={<LogoutPage />} />
          <Route path="/" element={<AppLayout />}>
            {/* Dashboard route */}
            <Route index element={<HomePage />} />
            {/* Subapp routes handled by qiankun */}
            <Route path="ui/issue/*" />
            <Route path="ui/admin/*" />
            <Route path="ui/maintenance/*" />
            <Route path="ui/moe/*" />
            <Route path="ui/vantage-dashboard/*" />
            <Route path="ui/reflow/*" />
            <Route path="ui/cbm/*" />
            <Route path="ui/smart-cleaning/*" />
            <Route path="ui/pipe-monitoring/*" />
            <Route path="*" element={<div>Not Found</div>} />
          </Route>

          {/* provide demo routes as physical page */}
          <Route path="/demo" element={<AppLayout />}>
            {/* Dashboard route */}
            <Route index element={<HomePage />} />
            {/* Subapp routes handled by qiankun */}
            <Route path="/demo/ui/issue/*" />
            <Route path="/demo/ui/admin/*" />
            <Route path="/demo/ui/maintenance/*" />
            <Route path="/demo/ui/moe/*" />
            <Route path="/demo/ui/vantage-dashboard/*" />
            <Route path="/demo/ui/reflow/*" />
            <Route path="/demo/ui/cbm/*" />
            <Route path="/demo/ui/smart-cleaning/*" />
            <Route path="/demo/ui/pipe-monitoring/*" />
            <Route path="*" element={<div>Not Found</div>} />
          </Route>
        </Routes>
      </Router>
    </Providers>
  ) : (
    <Router>
      <Routes>
        <Route
          path="/login/oidc/callback"
          element={<LoginPage isAuthenticated={false} />}
        />
        <Route path="/logout/oidc/callback" element={<LogoutPage />} />
        <Route path="*" element={<LoginPage isAuthenticated={false} />} />
      </Routes>
    </Router>
  );
}
