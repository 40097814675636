import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  widget: {
    padding: "0px",
  },
  root: {
    overflow: "auto",
    minHeight: "150px",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  asset: {
    textDecoration: "underline",
    cursor: "pointer",
    color: "#003F2D",
  },
  headerText: {
    color: "#1A1A1AA6",
    fontSize: "14px",
    marginLeft: "8px",
  },
  eventCell: {
    height: "15px",
    display: "flex",
    alignItems: "center",
  },
  costImpact: {
    "costImpact::before": {
      content: "$",
    },
    textAlign: "right",
  },
  healthScoreCell: {
    display: "flex",
    alignItems: "center",
  },
});

export default useStyles;
