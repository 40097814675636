import { NavigationProvider } from "@/components/NavigationProvider";
import { SidebarProvider } from "@/context/sidebar";
import { TanstackReactQueryProvider } from "@/TanstackReactQueryProvider";
import { ThemeProvider } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import { theme } from "@/theme";
import { DrawerProvider } from "./DrawerProvider";
import { SnackbarProvider } from "./SnackbarContext";

export function Providers(props: PropsWithChildren) {
  const { children } = props;

  return (
    <TanstackReactQueryProvider>
      <NavigationProvider>
        <SidebarProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <DrawerProvider>{children}</DrawerProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SidebarProvider>
      </NavigationProvider>
    </TanstackReactQueryProvider>
  );
}
