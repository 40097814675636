import { DashboardProvider } from "@/context/dashboard";
import { useSidebarContext } from "@/context/sidebar";
import { getFeatureFlag } from "@/utils/getFeatureFlag";
import {
  Button,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "@smartfm/react-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useNavigationCtx } from "../../src/components/NavigationProvider.jsx";
import { useSnackbarContext } from "../../src/components/layouts/SnackbarContext.jsx";
import { getWidgetMapping } from "../../src/utils/functions.js";
import RefreshButton from "../components/Button/RefreshButton.jsx";
import ErrorMessage from "../components/ErrorMessage.jsx";
import RecommendedActionContainer from "../components/RecommendedActions/RecommendedActionContainer.jsx";
import "../components/cards/widgets.scss";
import {
  getInterval,
  getWidgetListByClient,
} from "../services/DashboardService.js";
import Api from "../utils/Api.js";
import {
  defaultLayouts,
  defaultToolbox,
  preselectedLayouts,
  topAppBarHeight,
} from "../utils/constants.js";
import { CascaderInstance } from "./../components/CascaderInstance.jsx";
import "./Home.scss";
import { WidgetsDrawer } from "./WidgetsDrawer.jsx";
import { defaultLayout } from "./defaultLayout.js";
import { widgets } from "./widgets.jsx";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const getFromLS = (key) => {
  let ls = {};
  if (window.localStorage) {
    try {
      ls = defaultLayout;
      window.localStorage.setItem("rgl-8", JSON.stringify(ls));
    } catch {
      // do nothing
    }
  }
  return ls[key];
};

const saveToLS = (key, value) => {
  if (global.localStorage) {
    global.localStorage.setItem("rgl-8", JSON.stringify(value));
  }
};

const HomePageContent = () => {
  const navigationCtx = useNavigationCtx();
  if (!navigationCtx) throw new Error("Navigation context is not available");

  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const snackbar = useSnackbarContext();

  const LSLayouts = getFromLS("layouts") || defaultLayouts;
  const LSToolbox = getFromLS("toolbox") || defaultToolbox;
  const defaultDashboard = getFromLS("currentDashboard") || "Custom Dashboard";
  const defaultDashboardName =
    getFromLS("currentDashboardTitle") || "Custom Dashboard";
  const { isOpen: open, setIsOpen: setOpen } = useSidebarContext();

  const defaultState = {
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: JSON.parse(JSON.stringify(LSLayouts)),
    toolbox: JSON.parse(JSON.stringify(LSToolbox)),
    currentDashboard: "Custom Dashboard",
    currentDashboardTitle: "Custom Dashboard",
  };

  const configuration = {
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: JSON.parse(JSON.stringify(LSLayouts)),
    toolbox: JSON.parse(JSON.stringify(LSToolbox)),
    currentDashboard: "Custom",
    currentDashboardTitle: "Custom Dashboard",
  };

  const [applicationList, setapplicationList] = useState(
    /** @type {null | unknown[]} */
    (null),
  );
  const [sideSheetState, setSideSheetState] = useState(false);
  const [dragState, setDragState] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [gridState, setGridState] = useState(defaultState);
  const [tempGridState, setTempGridState] = useState(defaultState);
  const [preselectedLayout, setPreselectedLayout] = useState(defaultDashboard);
  const [widgetCategory, setWidgetCategory] = useState("All");
  const [dashboardTitle, setDashboardTitle] = useState(defaultDashboardName);

  const widgetsListQuery = useQuery({
    queryKey: ["getWidgetList", projectId],
    queryFn: async () => {
      if (projectId) {
        const response = await getWidgetListByClient(projectId);
        return response;
      }
      return null;
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
  });

  const getApplicationList = async () => {
    try {
      const oktaToken = JSON.parse(localStorage.getItem("okta-token-storage"));

      const response = await Api.get("/getUserPermissions");

      if (response != "") {
        const newApplicationList = response.data.permissions.directAccess.map(
          (applications) => {
            return {
              label: applications.application_name,
              icon: (
                <span className="material-symbols-outlined">
                  {applications.icon_url}
                </span>
              ),
              link:
                "/" +
                (applications.nav_path === "ui/smart-cleaning"
                  ? "ui/dynamic-service"
                  : applications.nav_path),
              details: applications.dis,
            };
          },
        );
        newApplicationList.sort((a, b) => a.label.localeCompare(b.label));
        setapplicationList(newApplicationList);
        return response;
      } else {
        window.location.reload(true);
      }
    } catch (error) {
      return {};
    }
  };

  const handleLayoutChange = (layout, layouts) => {
    setGridState((prevState) => ({
      currentBreakpoint: prevState.currentBreakpoint,
      compactType: prevState.compactType,
      mounted: prevState.mounted,
      toolbox: prevState.toolbox,
      layouts: layouts,
      currentDashboard: preselectedLayout,
      currentDashboardTitle: dashboardTitle,
    }));

    if (sideSheetState) {
      setSaveState(true);
    }
  };
  const handleDragOrResizeStart = () => {
    setDragState(true);
  };
  const handleDragOrResizeStop = () => {
    setPreselectedLayout("Custom");
    setDashboardTitle("Custom Dashboard");
    setDragState(false);
  };
  const handleResize = (
    layouts,
    oldLayoutItem,
    layoutItem,
    placeholder,
    e,
    element,
  ) => {
    // `oldLayoutItem` contains the state of the item before the resize.
    // You can modify `layoutItem` to enforce constraints.

    const cardType = element.parentElement.getAttribute("type");
    if (cardType === "pie" || cardType === "bar" || cardType === "column") {
      if (layoutItem.h === 4) {
        layoutItem.h = 5;
        placeholder.h = 5;
      }
    }
  };
  const handleCancelLayout = () => {
    // if (tempGridState.currentDashboard === 'Custom') {
    refetch();
    setPreselectedLayout("Custom");
    setDashboardTitle("Custom Dashboard");
    // } else {
    //   setGridState({
    //     ...tempGridState,
    //     currentDashboard: tempGridState.currentDashboard,
    //     currentDashboardTitle: tempGridState.currentDashboardTitle
    //   });
    //   setPreselectedLayout(tempGridState.currentDashboard);
    //   setDashboardTitle(tempGridState.currentDashboardTitle);
    // }
    setSideSheetState(false);
    setSaveState(false);
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["getDashboard"],
    queryFn: async () => {
      // const response = await Api.get('/dashboard/all?config=true');
      const response = await Api.post("/dashboard/get", {});
      const savedConfig = response?.data?.data?.configuration;
      if (savedConfig) {
        setGridState(savedConfig);
        setPreselectedLayout(savedConfig.currentDashboard);
        setDashboardTitle(savedConfig.currentDashboardTitle);
      }
      return savedConfig;
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
  });

  const {
    data: intervalData,
    error: intervalError,
    isLoading: intervalLoading,
    refetch: refetchInterval,
  } = useQuery({
    queryKey: ["getRefreshInterval"],
    queryFn: async () => {
      try {
        const response = await getInterval();
        return response?.data;
      } catch {
        return null;
      }
    },
  });

  const saveGridState = async () => {
    try {
      const response = await Api.post("/dashboard/save", {
        // dashboardName: 'Custom',
        configuration: gridState,
      }); //TODO: Replace with Actual API once received from backend
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveLayout = () => {
    setTempGridState({
      ...gridState,
      currentDashboard: preselectedLayout,
      currentDashboardTitle: dashboardTitle,
    });
    if (gridState.currentDashboard === "Custom") {
      saveGridState();
    }

    setSideSheetState(false);
    setSaveState(false);
    saveToLS("gridState", gridState);
    // Added Success message
    snackbar.setSnack({
      title: "Success",
      message: "Dashboard Saved successfully",
      autoHideDuration: 5000,
      severity: "success",
      open: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      onClose: () => {},
    });
  };

  const handleNewDashboard = (event) => {};

  const handlePreselectedLayout = (event) => {
    const selectedLayout = event.target.value;
    const preselectedLayoutWidgetIds = preselectedLayouts[
      selectedLayout
    ].lg.map((a) => a.i);
    setPreselectedLayout(selectedLayout);

    const updatedToolbox = { ...defaultToolbox };
    Object.keys(updatedToolbox).forEach((key) => {
      updatedToolbox[key] = updatedToolbox[key].filter((item) => {
        return !preselectedLayoutWidgetIds.includes(item.i);
      });
    });

    if (selectedLayout === "Custom") {
      refetch();
    } else {
      setGridState((prevState) => ({
        currentBreakpoint: prevState.currentBreakpoint,
        compactType: prevState.compactType,
        mounted: prevState.mounted,
        toolbox: updatedToolbox,
        layouts: preselectedLayouts[selectedLayout],
        currentDashboard: preselectedLayout,
        currentDashboardTitle: dashboardTitle,
      }));
    }
    setDashboardTitle(`${selectedLayout} Dashboard`);
    setSaveState(true);
  };
  const handleWidgetCategory = (event) => {
    setWidgetCategory(event.target.value);
  };
  const handleDashboardTitle = (event) => {
    setDashboardTitle(event.target.value);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    // LoadGridState();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
    localStorage.setItem("navdrawerstateopen", "false");
    setSideSheetState(!sideSheetState);
  };
  const onTakeItem = (itemToTake) => {
    setPreselectedLayout("Custom");
    setDashboardTitle("Custom Dashboard");

    const item = structuredClone(itemToTake);

    // 4 is number of columns
    item.x = gridState.layouts[gridState.currentBreakpoint].length % 4;
    // Infinity puts it at the bottom
    item.y = Infinity;

    setGridState(function (prevState) {
      const newLayouts = { ...prevState.layouts };
      Object.keys(newLayouts).forEach((key) => {
        newLayouts[key] = [...prevState.layouts[key], item];
      });

      return {
        currentBreakpoint: prevState.currentBreakpoint,
        compactType: prevState.compactType,
        mounted: prevState.mounted,
        toolbox: {
          ...prevState.toolbox,
          [prevState.currentBreakpoint]: prevState.toolbox[
            prevState.currentBreakpoint
          ].filter(({ i }) => i !== item.i),
        },
        layouts: newLayouts,
        currentDashboard: preselectedLayout,
        currentDashboardTitle: dashboardTitle,
      };
    });
  };
  const onPutItem = (item) => {
    setGridState(function (prevState) {
      const newLayouts = { ...prevState.layouts };
      Object.keys(newLayouts).forEach((key) => {
        newLayouts[key] = [...prevState.layouts[key]].filter(
          ({ i }) => i !== item.i,
        );
      });

      return {
        currentBreakpoint: prevState.currentBreakpoint,
        compactType: prevState.compactType,
        mounted: prevState.mounted,
        toolbox: {
          ...prevState.toolbox,
          [prevState.currentBreakpoint]: [
            ...(prevState.toolbox[prevState.currentBreakpoint] || []),
            item,
          ],
        },
        layouts: newLayouts,
        currentDashboard: preselectedLayout,
        currentDashboardTitle: dashboardTitle,
      };
    });
  };

  const generateDOM = () => {
    return gridState.layouts[gridState.currentBreakpoint].map((layoutItem) => {
      const widget = widgets.find((widget) => {
        return widget.id === layoutItem.i;
      });

      if (!widget) {
        return null;
      }

      const hiddenWidgetIds = getFeatureFlag("hiddenWidgetIds");
      if (hiddenWidgetIds.includes(widget.id)) {
        return null;
      }

      // Check widget visibility
      let isVisible = widgetsListQuery?.data?.data?.find(
        (w) => w === getWidgetMapping(widget?.id.toString()),
      );
      if (widget.id == "10") isVisible = true;

      return (
        isVisible && (
          <div key={layoutItem.i} type={widget.type}>
            <div className="widget-wrapper">
              <div
                className="hide-button"
                onClick={onPutItem.bind(this, layoutItem)}
              >
                <span className="material-icons" style={{ fontSize: "20px" }}>
                  close
                </span>
              </div>
              {widget?.content}
            </div>
          </div>
        )
      );
    });
  };
  const NoWidgets = () => {
    return (
      <div className="no-widgets">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src="/edit-dashboard.png"
            width="304"
            style={{ marginBottom: "12px" }}
          />

          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "34px",
              lineHeight: "40px",
              color: "#1A1A1A",
              letterSpacing: 0,
            }}
          >
            Let’s set up your dashboard
          </Typography>

          <Typography
            sx={{
              marginTop: "20px",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#1A1A1A",
              letterSpacing: 0,
            }}
          >
            You can add, resize and rearrange widgets to your preference.
          </Typography>

          <Button
            id="admin-add-new-user"
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#003f2d",
              color: "#ffffff",
              padding: "12px 16px 12px 16px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "16px",
              textTransform: "none",
              letterSpacing: 0,
              boxShadow: "none",
              width: "fit-content",
            }}
            onClick={toggleDrawer("right", !sideSheetState)}
          >
            Setup dashboard
          </Button>
        </div>
      </div>
    );
  };
  const DashboardButtons = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
          gap: "12px",
          padding: "16px 24px 0 24px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "50px",
            transform: "translate(-50%, -50%)",
            backgroundImage: 'url("/icons/Bell.svg")',
            backgroundRepeat: "no-repeat",
            width: "100px",
            height: "100px",
            zIndex: 0,
          }}
        ></div>
        <div>
          <Typography
            sx={{
              color: "#1A1A1A",
              fontSize: "24px",
              lineHeight: "32px",
              letterSpacing: 0,
              fontWeight: 500,
              zIndex: 1,
            }}
          >
            SmartFM Insights
          </Typography>
        </div>

        {sideSheetState ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCancelLayout}
              sx={{
                backgroundColor: "transparent",
                color: "#003f2d",
                padding: "8px 16px",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "16px",
                textTransform: "none",
                letterSpacing: 0,
                boxShadow: "none",
                "&.Mui-disabled": {
                  backgroundColor: "transparent",
                  color: "#1A1A1A46",
                },
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#003F2D08",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveLayout}
              disabled={!saveState}
              sx={{
                backgroundColor: "003f2d",
                color: "#ffffff",
                padding: "8px 16px",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "16px",
                textTransform: "none",
                letterSpacing: 0,
                boxShadow: "none",
                "&.Mui-disabled": {
                  backgroundColor: "#1A1A1A12",
                  color: "#1A1A1A46",
                },
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#003F2D",
                },
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", gap: "8px" }}>
            {!intervalLoading ? (
              <RefreshButton
                isLoading={intervalLoading}
                intervalData={intervalData}
                onRefresh={() => {}}
              />
            ) : (
              <CircularProgress size={"34px"} />
            )}
            {/* <Tooltip title="Coming soon!" placement="top" arrow>
              <span>
                <Button
                  variant="contained"
                  disabled={true}
                  sx={{
                    border: '1px solid #003f2d',
                    backgroundColor: 'transparent',
                    color: '#003f2d',
                    padding: '8px 16px',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '16px',
                    textTransform: 'none',
                    letterSpacing: 0,
                    boxShadow: 'none',
                    '&.Mui-disabled': {
                      backgroundColor: 'transparent',
                      borderColor: '#1A1A1A46',
                      color: '#1A1A1A46'
                    },
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#003F2D08'
                    }
                  }}
                  onClick={handleNewDashboard}
                >
                  New dashboard
                </Button>
              </span>
            </Tooltip> */}

            <Button
              variant="contained"
              disabled={sideSheetState}
              sx={{
                backgroundColor: "003f2d",
                color: "#ffffff",
                padding: "8px 16px",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "16px",
                textTransform: "none",
                letterSpacing: 0,
                boxShadow: "none",
                "&.Mui-disabled": {
                  backgroundColor: "#1A1A1A12",
                  color: "#1A1A1A46",
                },
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#003F2D",
                },
              }}
              onClick={toggleDrawer("right", !sideSheetState)}
            >
              Edit dashboard
            </Button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    refetch();
    getApplicationList();
  }, []);
  if (applicationList && applicationList.length === 0)
    throw new Error("user is not authorize to access project list");
  return (
    <div
      style={{ overflow: "auto", height: `calc(100vh - ${topAppBarHeight})` }}
      className={`${sideSheetState === true ? "open" : ""}`}
    >
      <>
        {applicationList ? (
          <div
            className={`home-page-container ${
              dragState === true ? "drag" : ""
            }`}
            style={{
              height: "100%",
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <>
              <div
                style={{
                  padding: "0 24px",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <CascaderInstance />
              </div>

              {widgetsListQuery?.data ? (
                <WidgetsDrawer
                  toggleDrawer={toggleDrawer}
                  sideSheetState={sideSheetState}
                  dashboardTitle={dashboardTitle}
                  handleDashboardTitle={handleDashboardTitle}
                  preselectedLayout={preselectedLayout}
                  handlePreselectedLayout={handlePreselectedLayout}
                  widgetCategory={widgetCategory}
                  handleWidgetCategory={handleWidgetCategory}
                  gridState={gridState}
                  onTakeItem={onTakeItem}
                  allowedWidgets={widgetsListQuery?.data?.data || []}
                />
              ) : null}
              {/* <GetWidgetList /> */}
              {gridState.layouts[gridState.currentBreakpoint].length > 0 ||
              sideSheetState ? (
                <div
                  style={{
                    padding: sideSheetState ? "24px" : 0,
                    flex: 1,
                    minWidth: "400px",
                  }}
                >
                  <div
                    style={{ width: "100%" }}
                    className={`${
                      sideSheetState === true ? "edit-section" : ""
                    }`}
                  >
                    <DashboardButtons />

                    {gridState.layouts[gridState.currentBreakpoint].length ===
                    0 ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#1A1A1A",
                            letterSpacing: 0,
                          }}
                        >
                          You can add, resize and rearrange widgets to your
                          preference.
                        </Typography>
                      </div>
                    ) : !widgetsListQuery.isLoading &&
                      widgetsListQuery.data &&
                      !isLoading ? (
                      <>
                        {!sideSheetState && <RecommendedActionContainer />}
                        <ResponsiveGridLayout
                          isDraggable={sideSheetState}
                          isResizable={sideSheetState}
                          onDragStart={handleDragOrResizeStart}
                          onDragStop={handleDragOrResizeStop}
                          onResizeStart={handleDragOrResizeStart}
                          onResizeStop={handleDragOrResizeStop}
                          onResize={handleResize}
                          onLayoutChange={handleLayoutChange}
                          breakpoints={{ lg: 996, sm: 768, xs: 0 }}
                          className="layout"
                          layouts={gridState.layouts}
                          cols={{ lg: 4, sm: 2, xs: 1 }}
                          rowHeight={28}
                          margin={[24, 24]}
                        >
                          {generateDOM()}
                          {/* {'testing'} */}
                        </ResponsiveGridLayout>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : (
                <NoWidgets />
              )}
            </>
          </div>
        ) : (
          <div className="home-page-loader">
            <CircularProgress sx={{ color: "primary.main" }} />
          </div>
        )}
      </>
    </div>
  );
};
export function HomePage() {
  return (
    <div
      style={{ overflow: "auto", height: `calc(100vh - ${topAppBarHeight})` }}
    >
      <ErrorBoundary
        FallbackComponent={({ error }) => (
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {error.message ===
            "user is not authorize to access project list" ? (
              <ErrorMessage
                errorTitle="No Access to any of the Applications"
                errorSubtitle="Please connect with Admin to get the access"
              />
            ) : (
              <ErrorMessage
                errorTitle="Error"
                errorSubtitle={"Something went wrong"}
              />
            )}
          </Stack>
        )}
      >
        <ThemeProvider theme={createTheme(theme)}>
          {/* <NavigationProvider> */}
          <DashboardProvider>
            <HomePageContent />
          </DashboardProvider>
          {/* </NavigationProvider> */}
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}
