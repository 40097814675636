import "./AppLayout.scss";

import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import SideBar from "./SideBar.jsx";

import { FeedbackIcon } from "@/components/icons/FeedbackIcon";
import FeedbackDrawer from "@/components/layouts/FeedbackDrawer";
import HTMLHead from "./HTMLHead";
import LegacyTopAppBar from "./TopAppBar";

import { AppHeader } from "@/components/AppHeader";
import NoInternet from "@/components/noInternet/NoInternet";
import { useSidebarContext } from "@/context/sidebar";
import { getFeatureFlag } from "@/utils/getFeatureFlag";
import { Toolbar } from "@mui/material";
import { useDrawerContext } from "./DrawerProvider";

import { IconButton } from "@mui/material";
import { UserAvatar } from "../profile/UserAvatar";

export function AppLayout() {
  const { header: isNewHeaderEnabled } = getFeatureFlag("smartfm2.0");
  const { setDrawerComponent } = useDrawerContext();

  const location = useLocation();
  const pathname = location.pathname;
  const { isOpen: open } = useSidebarContext();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [collapsed, setCollapsed] = useState(!open);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(window.navigator.onLine);
    }

    function handleOnline() {
      window.location.reload();
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const drawerCallback = (drawerStatus) => {
    setCollapsed(drawerStatus);
  };

  return (
    <>
      <HTMLHead />
      <Box sx={{ position: "relative" }}>
        {isNewHeaderEnabled ? (
          <Box sx={{ position: "fixed", zIndex: 1, left: 0, right: 0 }}>
            <AppHeader
              customItems={[
                <IconButton
                  key="feedback"
                  onClick={() => setDrawerComponent(<FeedbackDrawer />)}
                >
                  <FeedbackIcon fontSize="small" />
                </IconButton>,
                <Box key="divider" sx={{ px: 0.25 }} />,
                <Box
                  key="avatar"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 36,
                    width: 36,
                    color: "primary.dark",
                  }}
                >
                  <UserAvatar />
                </Box>,
              ]}
            />
          </Box>
        ) : (
          <LegacyTopAppBar />
        )}
        {isOnline ? (
          <Box sx={{ position: "relative", zIndex: 0, display: "flex" }}>
            <SideBar
              parentCallback={drawerCallback}
              collapsed={collapsed}
              pathname={pathname}
            />

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 0,
              }}
            >
              <Toolbar
                sx={
                  isNewHeaderEnabled
                    ? (theme) => ({
                        [theme.breakpoints.up("sm")]: {
                          minHeight: 48,
                        },
                      })
                    : undefined
                }
              />
              <Outlet />
              <main className="subapp-container" id="subapp-container" />
            </Box>
          </Box>
        ) : (
          <NoInternet />
        )}
      </Box>
    </>
  );
}
