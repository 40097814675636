import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  widgetContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    zIndex: 1,
  },
  widgetCard: {
    height: "295px",
    width: "320px",
    padding: "0px !important",
    borderRadius: "8px",
    border: "1px solid #BDA5E5",
    boxShadow: "0px 1px 3px 0px #00000024",
  },
  widgetCardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
  },
  widgetHead: {
    backgroundImage: `linear-gradient(93.57deg, rgba(48, 54, 90, 0.9) 22.23%, rgba(49, 48, 107, 0.378) 69.7%, rgba(48, 54, 90, 0.81) 87.04%),radial-gradient(63.73% 63.73% at 58.21% 21.08%, rgba(97, 47, 114, 0.312) 0%, rgba(166, 106, 214, 0.6) 100%), url('/cardbg.png')`, // cspell:disable-line
    height: "102px",
    backgroundSize: "contain",
  },
  widgetHeadContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 20px",
  },
  widgetHeading1: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  widgetDate: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  widgetHeading2: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  widgetHeading3: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    opacity: 0.8,
  },
  widgetBody: {
    padding: "24px 20px",
    display: "flex",
    flexDirection: "column",
  },
  widgetBodyText: {
    fontSize: "14px",
    lineHeight: "20px",
    paragraph: "true",
    paddingBottom: "12px",
    display: "block",
    whiteSpace: "pre-wrap",
  },
  widgetBodyTextBold: {
    fontWeight: 500,
    fontSize: "14px",
  },
  widgetBodyTextBoldRed: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#FF543E",
  },
  recommendationContainer: {
    borderLeft: "4px solid #8585E0",
    paddingLeft: "12px",
  },
  recommendationHeadingText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#8585E0",
  },
  recommendationText: {
    height: "96px",
    color: "#1A1A1A",
    overflow: "auto",
  },
  insightUnderline: {
    width: "16px",
    height: "1px",
    background: "#17E88F",
  },
  container: {
    position: "relative",
    margin: "16px 24px 0px 24px",
  },
  scrollContainer: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
  stack: {
    paddingRight: "20px",
  },
  item: {
    display: "inline-block",
    "&:last-child": {
      paddingRight: "1px",
    },
  },
  iconButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "#ffffff",
    zIndex: 2,
    border: "1px solid #e3e3e3",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
});
