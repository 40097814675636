import { PropsWithChildren, useState } from "react";
import { DashboardContext } from "./DashboardContext";

type DashboardProviderProps = PropsWithChildren;

export function DashboardProvider(props: DashboardProviderProps) {
  const { children } = props;

  // TODO: This part requires refactoring
  let refreshTime = localStorage.getItem("refreshTime");
  if (refreshTime === null) {
    localStorage.setItem("refreshTime", "5");
    refreshTime = "5";
  }
  const [refreshDuration, setRefreshDuration] = useState(
    parseInt(refreshTime) || 5,
  );
  const [refreshEvent, triggerRefreshEvent] = useState(false);
  const triggerRefresh = () => {
    triggerRefreshEvent((prevState) => !prevState);
  };

  return (
    <DashboardContext.Provider
      value={{
        refreshDuration,
        setRefreshDuration,
        refreshEvent,
        triggerRefresh,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
