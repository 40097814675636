import { useActiveAppName } from "@/hooks/useActiveAppName";
import { EmeraldAppHeader } from "@emerald-react/app-header";
import { Box, BoxProps } from "@mui/material";
import { ComponentProps } from "react";
import "./styles.css";

type EmeraldAppHeaderProps = ComponentProps<typeof EmeraldAppHeader>;

const delimiterSx = { paddingX: 2 } satisfies BoxProps["sx"];

const emeraldAppHeaderStyle = {
  boxSizing: "border-box",
} satisfies EmeraldAppHeaderProps["style"];
type AppHeaderProps = Omit<EmeraldAppHeaderProps, "logo" | "style">;

export function AppHeader(props: AppHeaderProps) {
  const activeAppName = useActiveAppName();
  const logo = (
    <>
      CBRE Smart FM <Box sx={delimiterSx}>|</Box> {activeAppName || "Home"}
    </>
  );

  return (
    <EmeraldAppHeader logo={logo} style={emeraldAppHeaderStyle} {...props} />
  );
}
