import { createSvgIcon, SvgIcon } from "@mui/material";
import "./Icons.scss";

import { Box } from "@mui/material";

const AccountCircle = () => {
  return <span className="material-symbols-outlined">account_circle</span>;
};

const AdminPanelSettings = () => {
  return (
    <span className="material-symbols-outlined">admin_panel_settings</span>
  );
};

const Alarm = () => {
  return <span className="material-symbols-outlined">alarm</span>;
};

const CleaningServices = () => {
  return <span className="material-symbols-outlined">cleaning_services</span>;
};

const Construction = () => {
  return <span className="material-symbols-outlined">construction</span>;
};

const ContactMail = () => {
  return <span className="material-symbols-outlined">contact_mail</span>;
};

const Handyman = () => {
  return <span className="material-symbols-outlined">handyman</span>;
};

const Logout = (props) => {
  return (
    <Box component="span" className="material-symbols-outlined" sx={props.sx}>
      logout
    </Box>
  );
};

const ModeFan = () => {
  return <img className="material_symbol_mode_fan" src="/icons/Mode_Fan.svg" />;
};

const FilledFan = () => {
  return (
    <img className="material_symbol_mode_fan" src="/icons/Fan_Filled.svg" />
  );
};

const Notifications = () => {
  return <span className="material-symbols-outlined">notifications</span>;
};

const PieChart = () => {
  return <span className="material-symbols-outlined">pie_chart</span>;
};

const Settings = () => {
  return <span className="material-symbols-outlined">settings</span>;
};

const ChevronLeft = () => {
  return <span className="material-symbols-outlined">chevron_left</span>;
};

const ChevronRight = () => {
  return <span className="material-symbols-outlined">chevron_right</span>;
};

const RightArrow = () => {
  return <span className="material-symbols-outlined">arrow_right_alt</span>;
};

const Menu = () => {
  return <span className="material-symbols-outlined">menu</span>;
};

const Warning = () => {
  return <span className="material-symbols-outlined">warning</span>;
};

const Done = () => {
  return (
    <span
      className="material-symbols-outlined"
      style={{
        fontSize: "14px",
        color: "#1A1A1A99",
        float: "right",
        marginLeft: "auto",
        marginRight: "0",
      }}
    >
      done
    </span>
  );
};

const Dashboard = () => {
  return <span className="material-symbols-outlined">dashboard</span>;
};

const ReflowServices = () => {
  return <span className="material-symbols-outlined">reflow services</span>;
};

const VantageUI = () => {
  return <span className="material-symbols-outlined">vantage ui</span>;
};

const HighPriority = () => {
  return (
    <img className="material_priority_symbol" src="/icons/Priority-High.svg" />
  );
};
const MediumPriority = () => {
  return (
    <img
      className="material_priority_symbol"
      src="/icons/Priority-Medium.svg"
    />
  );
};
const LowPriority = () => {
  return (
    <img className="material_priority_symbol" src="/icons/Priority-Low.svg" />
  );
};

const GoodHealthFace = () => {
  return (
    <span
      className="material-symbols-outlined material_ahs_symbol"
      style={{ color: "#50AF78" }}
    >
      mood
    </span>
  );
};
const FairHealthFace = () => {
  return (
    <span
      className="material-symbols-outlined material_ahs_symbol"
      style={{ color: "#FF9742" }}
    >
      sentiment_neutral
    </span>
  );
};
const PoorHealthFace = () => {
  return (
    <span
      className="material-symbols-outlined material_ahs_symbol"
      style={{ color: "#FF543E" }}
    >
      sentiment_dissatisfied
    </span>
  );
};

const MediumSeverityIcon = createSvgIcon(
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.35259 15.3777H14.6476C15.8026 15.3777 16.5226 14.1252 15.9451 13.1277L10.2976 3.3702C9.72009 2.3727 8.28009 2.3727 7.70259 3.3702L2.05509 13.1277C1.47759 14.1252 2.19759 15.3777 3.35259 15.3777ZM9.00009 10.1277C8.58759 10.1277 8.25009 9.7902 8.25009 9.3777V7.8777C8.25009 7.4652 8.58759 7.1277 9.00009 7.1277C9.41259 7.1277 9.75009 7.4652 9.75009 7.8777V9.3777C9.75009 9.7902 9.41259 10.1277 9.00009 10.1277ZM9.75009 13.1277H8.25009V11.6277H9.75009V13.1277Z" />
  </svg>,
  "MediumSeverity",
);

export {
  AccountCircle,
  AdminPanelSettings,
  Alarm,
  CleaningServices,
  Construction,
  ContactMail,
  Handyman,
  Logout,
  ModeFan,
  MediumSeverityIcon,
  Notifications,
  PieChart,
  Settings,
  ChevronLeft,
  ChevronRight,
  RightArrow,
  Menu,
  Warning,
  Done,
  Dashboard,
  ReflowServices,
  VantageUI,
  FilledFan,
  HighPriority,
  MediumPriority,
  LowPriority,
  GoodHealthFace,
  FairHealthFace,
  PoorHealthFace,
};
