import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: { allVariants: { fontFamily: '"calibre", sans-serif' } },
  palette: {
    mode: "light",
    primary: {
      main: "#003F2D",
      light: "#003F2D",
      dark: "#012A2D",
      contrastText: "#e0e8e6",
    },
    secondary: {
      main: "#778F9C",
      light: "#778F9C",
      dark: "#032842",
    },
    background: {
      default: "#FBFCFC",
      paper: "#FFF",
    },
    text: {
      primary: "#1A1A1A",
      secondary: "rgba(26,26,26,0.6)",
      disabled: "rgba(26,26,26,0.46)",
    },
    error: {
      main: "#FF543E",
      light: "#FF543E",
      dark: "#A03530",
    },
    warning: {
      main: "#FF9742",
      light: "#FF9742",
      dark: "#B85F00",
    },
    info: {
      main: "#8585E0",
      light: "#8585E0",
      dark: "#262698",
    },
    success: {
      main: "#50AF78",
      light: "#50AF78",
      dark: "#28573C",
    },
    issueStatus: {
      new: "#A388BF",
      waiting: "#CAD1D3",
      open: "#3E7CA6",
      deferred: "#D2785A",
      complete: "#50AF78",
    },
    divider: "rgba(0,63,45,0.15)",
  },
});

export { theme };
