import "./index.scss";

import React from "react";
import ReactDOMClient from "react-dom/client";
import App from "./App.jsx";
import { loadAllMicroApps } from "./MicroApps.jsx";

const container = document.getElementById("container-root");
if (!container) throw new Error("Container root element not found");

ReactDOMClient.createRoot(container).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

loadAllMicroApps();
