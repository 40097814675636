export const statusColor = {
  good: "#50AF78",
  fair: "#FF9742",
  poor: "#FF543E",
  not_applicable: "#CAD1D3",
};
export const timeDurationOptions = [
  { label: "This week", value: "thisWeek" },
  { label: "Last week", value: "lastWeek" },
  { label: "This month", value: "thisMonth" },
  { label: "Last month", value: "lastMonth" },
  { label: "Last 30 days", value: "last30Days" },
];
export const ERROR_MSG = {
  widgetApiErrorMessage: {
    noDataFoundForDateRange: "No data for date range",
    noDataForRecord: "No data for record",
    buildingNotFound: "Building not found",
  },
};
export const topAppBarHeight = "64px";
export const defaultToolbox = {
  lg: [
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "1",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '2',
    //   moved: false,
    //   static: false
    // },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "5",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "8",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "9",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "10",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "11",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '12',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '13',
    //   moved: false,
    //   static: false
    // },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "14",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    {
      w: 1,
      h: 8,
      i: "2",
      minH: 3,
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 8,
      i: "19",
      minH: 3,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "1",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "2",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "5",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "8",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "9",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "10",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "11",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '12',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '13',
    //   moved: false,
    //   static: false
    // },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "14",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    {
      w: 1,
      h: 8,
      i: "19",
      minH: 3,
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "1",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '2',
    //   moved: false,
    //   static: false
    // },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "5",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "8",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "9",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "10",
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "11",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '12',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '13',
    //   moved: false,
    //   static: false
    // },
    {
      w: 1,
      h: 6,
      minH: 3,
      i: "14",
      moved: false,
      static: false,
    },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '15',
    //   moved: false,
    //   static: false
    // },
    // {
    //   w: 1,
    //   h: 6,
    //   minH: 3,
    //   i: '16',
    //   moved: false,
    //   static: false
    // }
    {
      w: 1,
      h: 8,
      i: "19",
      minH: 3,
      moved: false,
      static: false,
    },
  ],
};

export const defaultLayouts = {
  lg: [],
  sm: [],
  xs: [],
};

export const preselectedLayouts = {
  "Facility Management": {
    lg: [
      // {
      //   w: 1,
      //   h: 6,
      //   x: 1,
      //   y: 0,
      //   i: '12',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 10,
        x: 0,
        y: 0,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 1,
        y: 6,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 3,
      //   x: 3,
      //   y: 0,
      //   i: '13',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 6,
        x: 2,
        y: 0,
        i: "14",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 2,
        y: 6,
        i: "5",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 3,
        y: 3,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 8,
      //   x: 3,
      //   y: 3,
      //   i: '15',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // }
    ],
    sm: [
      // {
      //   w: 1,
      //   h: 6,
      //   x: 0,
      //   y: 6,
      //   i: '12',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 0,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 15,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 6,
      //   x: 1,
      //   y: 4,
      //   i: '13',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 6,
        x: 1,
        y: 10,
        i: "14",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 7,
        x: 1,
        y: 16,
        i: "5",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 5,
      //   x: 0,
      //   y: 18,
      //   i: '15',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // }
    ],
    xs: [
      // {
      //   w: 1,
      //   h: 3,
      //   x: 0,
      //   y: 0,
      //   i: '12',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 44,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 3,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 3,
      //   x: 0,
      //   y: 6,
      //   i: '13',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 9,
        i: "14",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 12,
        i: "5",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 1,
      //   h: 6,
      //   x: 0,
      //   y: 18,
      //   i: '15',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // }
    ],
  },
  "Energy management": {
    lg: [
      {
        w: 1,
        h: 11,
        x: 0,
        y: 5,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 1,
        y: 0,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 2,
        y: 0,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 0,
        y: 0,
        i: "11",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 3,
      //   h: 8,
      //   x: 1,
      //   y: 8,
      //   i: '2',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 8,
        x: 3,
        y: 0,
        i: "1",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 1,
        h: 6,
        x: 0,
        y: 0,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 15,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 3,
        y: null,
        i: "11",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 3,
      //   h: 8,
      //   x: 0,
      //   y: null,
      //   i: '2',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 11,
        x: 1,
        y: null,
        i: "1",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 1,
        h: 6,
        x: 0,
        y: 44,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 3,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 3,
        y: null,
        i: "11",
        minH: 3,
        moved: false,
        static: false,
      },
      // {
      //   w: 3,
      //   h: 8,
      //   x: 0,
      //   y: null,
      //   i: '2',
      //   minH: 3,
      //   moved: false,
      //   static: false
      // },
      {
        w: 1,
        h: 11,
        x: 1,
        y: null,
        i: "1",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
  },
  "Remote operations center": {
    lg: [],
    sm: [],
    xs: [],
  },
  "Janitorial procurement": {
    lg: [],
    sm: [],
    xs: [],
  },
  Custom: {
    lg: [],
    sm: [],
    xs: [],
  },
};
