import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useDashboardContext } from "@/context/dashboard";
import { api } from "../../../utils/Api";
import { useNavigationCtx } from "../../NavigationProvider.jsx";
import PieChart from "../../charts/PieChart";
import { PercentageBar } from "../PercentageBar.jsx";
import { WidgetCard, dateRangeId } from "../WidgetCard.jsx";
import "./Legionella.scss";

export default function Legionella() {
  const title = "Pipe Monitoring";
  const widgetType = "legionellaCompliance";
  const { refreshDuration, refreshEvent } = useDashboardContext();
  const [dateRange, setDateRange] = useState("This month");
  const navigationCtx = useNavigationCtx();
  const interval = dateRangeId(dateRange);
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const query = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn:
      /**
       * @typedef {{
       *     widgetType: string;
       *     interval: string;
       *     buildingId: string;
       *     projectId: string;
       *     startDate: string;
       *     endDate: string;
       *     values: {
       *       field: string;
       *       value: number;
       *     }[];
       *     failingOutlets: number;
       *     arrow: "up" | "down";
       *     comparison: string;
       *   }} APIResponseData
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           * */
          const response = await api.get(
            `legionella/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType,
                interval,
              },
            },
          );
          if (response.data.status === "error")
            if (response.data.errorMessage)
              return {
                status: "error",
                errorMessage:
                  response.data.errorMessage === "No data for date range" ||
                  response.data.errorMessage === "No data for record"
                    ? response.data.errorMessage
                    : "unknown error",
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'",
              );
          if (!response.data.data)
            throw new Error("unable to find 'data' property in API response");
          return { status: "success", data: response.data.data };
        } catch (e) {
          console.error(e || "some error occurred");
          return {
            status: "error",
            errorMessage: "unknown error",
          };
        }
      },
  });

  const data = useMemo(
    () =>
      query.data?.status === "success"
        ? [
            {
              name: "Fail compliance",
              value:
                query.data?.data?.values.find((v) => v.field === "Fail")
                  ?.value ?? 0,
              color: "#C03333",
            },
            {
              name: "Pass compliance",
              value:
                query.data?.data?.values.find((v) => v.field === "Pass")
                  ?.value ?? 0,
              color: "#0D9C95",
            },
            {
              name: "In progress",
              value:
                query.data?.data?.values.find((v) => v.field === "In progress")
                  ?.value ?? 0,
              color: "#CAD1D3",
            },
            {
              name: "Not known",
              value:
                query.data?.data?.values.find((v) => v.field === "Not known")
                  ?.value ?? 0,
              color: "#CAD1D3",
            },
            {
              name: "Needs attention",
              value:
                query.data?.data?.values.find(
                  (v) => v.field === "Needs attention",
                )?.value ?? 0,
              color: "#FF9742",
            },
            {
              name: "Not started",
              value:
                query.data?.data?.values.find((v) => v.field === "Not started")
                  ?.value ?? 0,
              color: "#3E7CA6",
            },
          ]
        : [],
    [query.data],
  );

  const getLink = () => {
    if (query.data && query.data?.errorMessage) {
      return "";
    }
    return `/ui/legionella-monitoring?intervalStart=${
      query.data?.status === "success" ? query.data.data.startDate : null
    }&intervalType=month&view=outlet&recordId=${buildingId}`;
  };
  return (
    <WidgetCard
      scrollContainer={
        <div className="widget-chart">
          <PercentageBar data={data} />
          <PieChart data={data} />
          <div
            className="legend"
            style={{
              flexGrow: 1,
              height: "100%",
              overflow: "auto",
            }}
          >
            <div className="legend-container">
              {data.map((entry, index) => (
                <div key={index} className="legend-row">
                  <div className="legend-row-label">
                    <span
                      className="legend-icon"
                      style={{ backgroundColor: entry.color }}
                    ></span>
                    <span className="legend-label">{entry.name}</span>
                  </div>
                  <span className="legend-value">{entry.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      useQueryResult={query}
      title={title}
      link={getLink()}
      dateRange={dateRange}
      setDateRange={setDateRange}
      headerValueComponent={
        query.data?.status === "success" ? (
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#1A1A1A",
              letterSpacing: 0,
              textTransform: "lowercase",
            }}
          >
            {`${query.data.data.failingOutlets} failing outlets`}
          </Typography>
        ) : null
      }
      valueChangeComponent={
        query.data?.status === "success" ? (
          <>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 14 }}
            >
              {query.data.data.arrow === "up"
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
            1 {dateRange}
          </>
        ) : null
      }
    />
  );
}
