import "./TopAppBar.scss";

import { PropsWithChildren, useState } from "react";

import { UserAvatar } from "@/components/profile/UserAvatar";
import { Link, useLocation } from "react-router-dom";

import { FeedbackIcon } from "@/components/icons/FeedbackIcon";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDrawerContext } from "./DrawerProvider.jsx";
import FeedbackDrawer from "./FeedbackDrawer.jsx";

const getActiveApp = () => {
  const url = window?.location?.href || "";
  if (url.includes("/ui/admin")) {
    return "Administration";
  } else if (url.includes("/ui/cbm")) {
    return "Condition Based Maintenance";
  } else if (url.includes("/ui/vantage-dashboard")) {
    return "Vantage Reports";
  } else if (url.includes("/ui/reflow")) {
    return "Building Details";
  } else if (url.includes("/ui/smart-cleaning")) {
    return "Smart Cleaning";
  } else if (url.includes("/ui/issue")) {
    return "Issues";
  } else if (url.includes("/ui/pipe-monitoring")) {
    return "Pipe Monitoring";
  } else if (url.includes("/ui/maintenance")) {
    return "Maintenance";
  } else if (url.includes("/ui/moe")) {
    return "Maintenance Optimization Engine";
  } else {
    return "Home";
  }
};

function Title(props: PropsWithChildren<{ color?: string }>) {
  const { children, color = "#1A1A1A" } = props;
  return (
    <Typography
      id="topbar-heading"
      variant="Calibre/H6 Headline"
      noWrap
      component="div"
      sx={{ color, letterSpacing: 0 }}
      className={"mdc-top-app-bar__title"}
    >
      {children}
    </Typography>
  );
}

function TopAppBar() {
  const [, setIsDrawerOpen] = useState(false);
  const { setDrawerComponent } = useDrawerContext();
  const handleIconClick = () => {
    setIsDrawerOpen(true);
    setDrawerComponent(<FeedbackDrawer />);
  };
  const location = useLocation();
  const path = location.pathname?.startsWith("/demo") ? "/demo" : "/";

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className="mui-top-app-bar"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
      }}
    >
      <Toolbar>
        <Stack direction="row" alignItems="center" gap="8px">
          <Link color="inherit" to={path}>
            <Title color="#003F2D">CBRE Smart FM Solutions</Title>
          </Link>
          <Title>|</Title>
          <Title>{getActiveApp()}</Title>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            marginLeft: "auto",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <IconButton
            sx={{ p: "6px", color: "primary.main" }}
            onClick={handleIconClick}
          >
            <FeedbackIcon fontSize="small" />
          </IconButton>
          <UserAvatar />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
