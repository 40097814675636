import {
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { category, widgets } from "./widgets.jsx";
import { getWidgetMapping } from "../utils/functions.js";
import { defaultLayout } from "./defaultLayout.js";
import { getFeatureFlag } from "@/utils/getFeatureFlag";

export const WidgetsDrawer = ({
  toggleDrawer,
  sideSheetState,
  dashboardTitle,
  handleDashboardTitle,
  preselectedLayout,
  handlePreselectedLayout,
  widgetCategory,
  handleWidgetCategory,
  gridState,
  onTakeItem,
  allowedWidgets = [],
}) => {
  const displayedToolboxes = (
    defaultLayout.toolbox[gridState.currentBreakpoint] ?? []
  )
    // don't show widgets in the list which already on the dashboard
    .filter(
      (toolbox) =>
        !gridState.layouts[gridState.currentBreakpoint]?.find(
          (layoutWidget) => layoutWidget.i === toolbox.i,
        ),
    )
    // filter by category
    .filter((toolbox) => {
      if (widgetCategory === "All") {
        return true;
      }

      return (
        widgets.find((widget) => widget.id === toolbox.i)?.category ===
        widgetCategory
      );
    });
  return (
    <Drawer
      sx={{
        width: 480,
        "& .MuiDrawer-paper": {
          width: 480,
          height: "calc(100vh - 56px)",
          top: 56,
          flexShrink: 0,
        },
      }}
      variant="persistent"
      anchor="right"
      open={sideSheetState}
      onClose={toggleDrawer("right", false)}
    >
      <div className="drawer-wrapper">
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="end"
          onClick={toggleDrawer("right", false)}
          sx={{ position: "absolute", right: "16px", top: "16px" }}
        >
          <span className="material-icons" style={{ fontSize: "20px" }}>
            close
          </span>
        </IconButton>

        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#1A1A1A",
            letterSpacing: 0,
          }}
        >
          Edit dashboard
        </Typography>

        {/* <TextField
          sx={{ marginTop: '20px' }}
          fullWidth
          key="Dashboard title"
          label="Dashboard title"
          variant="outlined"
          value={dashboardTitle}
          onChange={handleDashboardTitle}
          // disabled
          inputProps={{
            readOnly: true
          }}
        /> */}

        {/* <FormControl fullWidth sx={{ marginTop: '20px' }}>
          <InputLabel>Dashboard layout</InputLabel>

          <Select
            label="Dashboard layout"
            value={preselectedLayout}
            onChange={handlePreselectedLayout}
            MenuProps={{
              disableScrollLock: true
            }}
            sx={{}}
          >
            {' '}
            <MenuItem value={'Facility Management'}>
              Facility Management
            </MenuItem>
            <MenuItem value={'Energy management'}>Energy management</MenuItem>
            <MenuItem value={'Remote operations center'}>
              Remote operations center
            </MenuItem>
            <MenuItem value={'Janitorial procurement'}>
              Janitorial procurement
            </MenuItem>
            <MenuItem value={'Custom'}>Custom</MenuItem>
          </Select>
        </FormControl> */}

        <Typography
          sx={{
            fontStyle: "normal",
            marginTop: "32px",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#1A1A1A",
            letterSpacing: 0,
          }}
        >
          Add widgets
        </Typography>

        <FormControl fullWidth sx={{ marginTop: "20px", marginBottom: "12px" }}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            value={widgetCategory}
            onChange={handleWidgetCategory}
            MenuProps={{
              disableScrollLock: true,
            }}
            sx={{}}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {Object.keys(category).map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
            {/* <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Assets'}>Assets</MenuItem> */}
            {/* <MenuItem value={'Work orders'}>Work orders</MenuItem> */}
            {/* <MenuItem value={'Service requests'}>Service requests</MenuItem> */}
            {/* <MenuItem value={'Maintenance'}>Maintenance</MenuItem> */}
            {/* <MenuItem value={'Energy & Sustainability'}>
              Energy & Sustainability
            </MenuItem> */}
            {/* <MenuItem value={'Legionella Monitoring'}>
              Legionella Monitoring
            </MenuItem>
            <MenuItem value={'Dynamic services'}>Dynamic services</MenuItem>
            <MenuItem value={'Weather'}>Weather</MenuItem> */}
          </Select>
        </FormControl>

        <div className="widget-list">
          <ToolBox
            items={displayedToolboxes}
            onTakeItem={onTakeItem}
            allowedWidgets={allowedWidgets}
          />
        </div>
      </div>
    </Drawer>
  );
};
export const ToolBox = ({ items, onTakeItem, allowedWidgets }) => {
  return (
    <div className="toolbox">
      <div className="toolbox__items">
        {items.map((item) => (
          <ToolBoxItem
            key={item.i}
            item={item}
            onTakeItem={onTakeItem}
            allowedWidgets={allowedWidgets}
          />
        ))}
      </div>
    </div>
  );
};
export const ToolBoxItem = ({ item, onTakeItem, allowedWidgets }) => {
  const widget = widgets.find((widget) => widget.id === item.i);
  if (!widget) {
    return null;
  }

  const hiddenWidgetIds = getFeatureFlag("hiddenWidgetIds");
  if (hiddenWidgetIds.includes(widget.id)) {
    return null;
  }

  // Check widget visibility
  let isVisible = allowedWidgets.includes(
    getWidgetMapping(widget.id.toString()),
  );
  if (widget.id == "10") isVisible = true;

  return (
    isVisible && (
      <div
        // style={{
        //   border: '1px solid black',
        //   boxSizing: 'border-box'
        // }}
        className="toolbox__items__item"
        onClick={onTakeItem.bind(undefined, item)}
      >
        {widget.content}
      </div>
    )
  );
};
