import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const pluralize = (count, noun, suffix = "s") => {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
};

export default function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) {
  const theme = useTheme();
  const disabledColor = theme.palette.divider;
  const totalPagesCount = Math.ceil(count / rowsPerPage);
  const lastPageIndex = Math.max(0, totalPagesCount - 1);

  return (
    <Stack direction={"row"} sx={{ flexShrink: 0, mx: 5, height: 60 }}>
      <IconButton
        role="button"
        onClick={(event) => {
          onPageChange(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPage sx={page === 0 && { fill: disabledColor }} />
        ) : (
          <FirstPage sx={page === 0 && { fill: disabledColor }} />
        )}
      </IconButton>
      <IconButton
        role="button"
        onClick={(event) => {
          onPageChange(event, Math.max(page - 1, 0));
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight sx={page === 0 ? { fill: disabledColor } : {}} />
        ) : (
          <KeyboardArrowLeft sx={page === 0 ? { fill: disabledColor } : {}} />
        )}
      </IconButton>

      <JumpToPageNumber
        handleButtonClick={(event, _) => {
          onPageChange(event, event.target.value * 1);
        }}
        currentPageIndex={page + 1}
        totalPagesCount={totalPagesCount}
      />
      <IconButton
        role="button"
        onClick={(event) => {
          onPageChange(event, Math.min(page + 1, lastPageIndex));
        }}
        disabled={page >= totalPagesCount - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft
            sx={page === totalPagesCount - 1 && { fill: disabledColor }}
          />
        ) : (
          <KeyboardArrowRight
            sx={page === totalPagesCount - 1 && { fill: disabledColor }}
          />
        )}
      </IconButton>
      <IconButton
        role="button"
        onClick={(event) => {
          onPageChange(event, lastPageIndex);
        }}
        disabled={page >= totalPagesCount - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPage
            sx={page === totalPagesCount - 1 && { fill: disabledColor }}
          />
        ) : (
          <LastPage
            sx={page === totalPagesCount - 1 && { fill: disabledColor }}
          />
        )}
      </IconButton>
    </Stack>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function JumpToPageNumber({
  handleButtonClick,
  currentPageIndex,
  totalPagesCount,
}) {
  const maxSiblingsPerSide = 0;
  const leftmostSiblingIndex =
    currentPageIndex - Math.min(maxSiblingsPerSide, currentPageIndex);
  const rightmostSiblingIndex = Math.max(
    0,
    Math.min(
      totalPagesCount - 1,
      currentPageIndex +
        Math.min(maxSiblingsPerSide, currentPageIndex + maxSiblingsPerSide),
    ),
  );
  const arr = [];
  for (let i = leftmostSiblingIndex; i <= rightmostSiblingIndex; i++) {
    arr.push(
      <IconButton
        key={i}
        role="button"
        onClick={handleButtonClick}
        disabled={currentPageIndex === i}
        aria-label={`page ${i + 1}`}
        sx={{
          // width: "2rem",
          // height: "2rem",
          // margin: "auto",
          ...(currentPageIndex === i
            ? {
                fontSize: "12px",
                borderRadius: 0,
              }
            : { fontSize: 14 }),
        }}
        value={i}
      >
        {i}
      </IconButton>,
    );
  }
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      fontSize={8}
      position={"relative"}
    >
      <Typography variant="body2">{`${currentPageIndex} of ${pluralize(totalPagesCount, "page")}`}</Typography>
    </Stack>
  );
}

JumpToPageNumber.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  totalPagesCount: PropTypes.number.isRequired,
};
