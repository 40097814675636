import {
  Backdrop,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import useStyles from "./useStyles.js";
import EnhancedPagination from "./EnhancedPagination.jsx";
import EnhancedTableHead from "./TableHead/EnhancedTableHead.jsx";
import { useEffect, useRef } from "react";

const CustomTableContainer = ({ isLoading, children, otherProps }) => {
  const classes = useStyles();

  return (
    <Card
      className="issue-card-table"
      component={Box}
      square
      borderRadius={0}
      sx={{ height: "100%", overflow: "hidden", position: "relative" }}
    >
      <Backdrop
        sx={{
          backgroundColor: !otherProps?.bgColor
            ? "rgba(255,255,255)"
            : otherProps.bgColor,
          position: "absolute",
          zIndex: 3,
        }}
        open={isLoading ?? false}
        className="table-empty-spinner"
      >
        <CircularProgress disableShrink />
      </Backdrop>
      <Box
        className={"widget" + classes.widget}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {children}
      </Box>
    </Card>
  );
};
CustomTableContainer.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  otherProps: PropTypes.object,
};
function EnhancedTable({
  columns,
  isLoading,
  rows,
  pagination,
  order,
  orderBy,
  requestSort,
  selected,
  selectable = false,
  selectAllClick,
}) {
  const theme = useTheme();
  const totalRows = pagination?.totalRows || rows?.length;
  const tableRef = useRef(null);
  const emptyRows =
    pagination.page > 0
      ? Math.max(0, (1 + pagination.page) * pagination.rowsPerPage - totalRows)
      : 0;

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pagination.page, pagination.rowsPerPage]);

  if (!rows || !columns || totalRows === 0)
    return (
      <CustomTableContainer isLoading={isLoading}>
        <div className="top" style={{ width: "100%", height: "100%" }}>
          <div className="top">{/* <WidgetCardTitle /> */}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography color={"rgba(26, 26, 26, 0.65)"}>
              No data available
            </Typography>
          </div>
        </div>
      </CustomTableContainer>
    );
  return (
    <CustomTableContainer isLoading={isLoading}>
      <div style={{ flex: 1, overflow: "hidden" }}>
        <TableContainer
          component={Box}
          sx={{ height: "100%", borderTop: "1px solid #d9e2e0" }}
          ref={tableRef}
        >
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={selectAllClick}
              onRequestSort={requestSort}
              rowCount={rows.length}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.id || index}
                  hover
                  sx={{
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: theme.palette.primary.contrastText,
                    },
                  }}
                  selected={selected.includes(row.id)}
                >
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selected.includes(row.id)}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      component="th"
                      id={`enhanced-table-checkbox-${index}`}
                      padding={column.disablePadding ? "none" : "normal"}
                      align={column.numeric ? column.align : "left"}
                      sx={column?.style}
                    >
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {pagination && totalRows > 0 && (
        <EnhancedPagination
          pagination={pagination}
          page={pagination.page}
          totalRows={pagination.totalRows || 0}
          rowsPerPage={pagination.rowsPerPage || 5}
        />
      )}
    </CustomTableContainer>
  );
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  selectable: PropTypes.bool,
  tableActions: PropTypes.node,
  tableName: PropTypes.string,
  totalRows: PropTypes.number,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  requestSort: PropTypes.func.isRequired,
  selectAllClick: PropTypes.func,
  selected: PropTypes.array,
};

export default EnhancedTable;
