import { config } from "@/config";

async function getUserIdByEmail(email: string) {
  const { uuidGeneratorService } = config;
  const { baseUrl, params } = uuidGeneratorService;

  const queryParams = new URLSearchParams(params);
  queryParams.set("email", email);

  const queryString = queryParams.toString();
  const url = `${baseUrl}?${queryString}`;
  let uuid = null;

  try {
    uuid = await fetch(url).then((response) => response.text());
  } catch (error) {
    console.error(error);
  }

  return uuid;
}

export { getUserIdByEmail };
