import { theme } from "@/theme";
import { ThemeProvider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  isAuthenticated: boolean;
}

export default function Login(props: LoginProps) {
  const { isAuthenticated } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate, isAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      <Stack
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
          width: "100dvw",
        }}
      >
        <CircularProgress />
      </Stack>
    </ThemeProvider>
  );
}
