import React, { useRef, useState, useEffect } from "react";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import RecommendedActions from "./RecommendedActions.jsx";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useStyles } from "./useStyles";
import dayjs from "dayjs";

import { api } from "./../../utils/Api";

/**
 * RecommendedActionContainer component fetches and displays recommended actions
 * for assets based on priority and cost insights. It uses two queries to fetch
 * data from the API and displays the results in a scrollable container.
 *
 * @component
 * @example
 * return (
 *   <RecommendedActionContainer />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @description
 * This component uses the `useQuery` hook from `react-query` to fetch asset insights
 * based on priority and cost. It displays the fetched data in a scrollable container
 * with navigation buttons to scroll left and right. If the data is still loading,
 * it shows loading placeholders. If no data is found, it displays a message indicating
 * that no recommendations were found.
 *
 * @function
 * @name RecommendedActionContainer
 *
 * @requires useSearchParams
 * @requires useQuery
 * @requires api
 * @requires dayjs
 * @requires useStyles
 * @requires useRef
 * @requires useState
 * @requires useEffect
 * @requires Box
 * @requires Stack
 * @requires Typography
 * @requires IconButton
 * @requires ArrowBackIosIcon
 * @requires ArrowForwardIosIcon
 * @requires RecommendedActions
 */
const RecommendedActionContainer = () => {
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  // Query to fetch asset insights based on priority
  const assetInsightsByPriorityQuery = useQuery({
    queryKey: ["getAssetInsightsByPriority", recordId],
    queryFn: async () => {
      const data = await api.post("issues/assetInsights", {
        site_ref: recordId,
        limit: 2,
        startDate: dayjs().subtract(30, "day").toISOString(),
        endDate: dayjs().toISOString(),
        order: "priority",
      });
      if (data?.status === 200 && data?.data?.length > 0) {
        try {
          const items = data.data.map((asset) => {
            const issues = asset?.issues || [];
            const issueCounts = issues.reduce(
              (acc, issue) => {
                acc[issue.priority] = (acc[issue.priority] || 0) + 1;
                return acc;
              },
              { P1: 0, P2: 0, P3: 0 },
            );
            const issueType = issueCounts.P1
              ? "P1s"
              : issueCounts.P2
                ? "P2s"
                : issueCounts.P3
                  ? "P3s"
                  : "";
            const issueCount =
              issueCounts.P1 || issueCounts.P2 || issueCounts.P3 || 0;
            return {
              date: "",
              title: "Assets Historical Issues",
              assetName: asset.equip_name,
              alarmCounts: asset?.total_events,
              issueCount,
              issueType,
              recommendedAction: asset?.recommended_action || "N/A",
            };
          });
          return items;
        } catch (error) {
          console.error("Error processing data:", error);
          throw new Error("Failed to process asset insights data");
        }
      } else {
        throw new Error(data?.response?.data?.data || "No data found");
      }
    },
  });

  // Query to fetch asset insights based on cost
  const assetInsightsByCostQuery = useQuery({
    queryKey: ["getAssetInsightsByCost", recordId],
    queryFn: async () => {
      const data = await api.post("issues/assetInsights", {
        site_ref: recordId,
        limit: 2,
        startDate: dayjs().subtract(30, "day").toISOString(),
        endDate: dayjs().toISOString(),
        order: "cost",
      });
      if (data?.status === 200 && data?.data?.length > 0) {
        try {
          const items = data.data.map((asset) => {
            return {
              date: "",
              title: "High Cost Assets",
              assetName: asset.equip_name,
              alarmCounts: asset?.total_events,
              costImpact: parseInt(asset?.current_total_cost),
              recommendedAction: asset?.recommended_action || "N/A",
            };
          });
          return items;
        } catch (error) {
          console.error("Error processing data:", error);
          throw new Error("Failed to process asset insights data");
        }
      } else {
        return [];
      }
    },
  });

  const classes = useStyles();
  const containerRef = useRef(null);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setCanScrollPrev(scrollLeft > 0);
      setCanScrollNext(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };

  const scrollNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 400, behavior: "smooth" });
      setTimeout(checkScrollPosition, 400); // Check scroll position after animation
    }
  };

  const scrollPrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -400, behavior: "smooth" });
      setTimeout(checkScrollPosition, 400); // Check scroll position after animation
    }
  };

  const isSuccess =
    assetInsightsByPriorityQuery.isSuccess ||
    assetInsightsByCostQuery.isSuccess;
  const isLoading =
    assetInsightsByPriorityQuery.isLoading ||
    assetInsightsByCostQuery.isLoading;
  const hasPriorityCards =
    (assetInsightsByPriorityQuery?.data || [])?.length > 0;
  const hasCostCards = (assetInsightsByCostQuery?.data || [])?.length > 0;

  useEffect(() => {
    checkScrollPosition();
    const handleScroll = () => checkScrollPosition();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [
    assetInsightsByPriorityQuery.isLoading,
    assetInsightsByCostQuery.isLoading,
    assetInsightsByPriorityQuery.data,
    assetInsightsByCostQuery.data,
  ]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Stack direction="row" spacing={2} className={classes.stack}>
          {Array.from({ length: 4 }, (_, index) => (
            <Box key={index} className={classes.item}>
              <RecommendedActions isLoading={true} />
            </Box>
          ))}
        </Stack>
      );
    }
    if (isSuccess && (hasCostCards || hasPriorityCards)) {
      return (
        <Stack direction="row" spacing={2} className={classes.stack}>
          {hasPriorityCards &&
            assetInsightsByPriorityQuery.data.map((item, index) => (
              <Box key={index} className={classes.item}>
                <RecommendedActions {...item} />
              </Box>
            ))}
          {hasCostCards &&
            assetInsightsByCostQuery.data.map((item, index) => (
              <Box key={index} className={classes.item}>
                <RecommendedActions {...item} />
              </Box>
            ))}
        </Stack>
      );
    }
    return (
      <Typography
        variant="Calibre/Body 1"
        sx={{ width: "100%", textAlign: "center" }}
      >
        No Recommendations Found
      </Typography>
    );
  };

  return (
    <Box className={classes.container}>
      {canScrollPrev && (
        <IconButton
          onClick={scrollPrev}
          className={`${classes.iconButton} ${classes.prevButton}`}
        >
          <ArrowBackIosIcon viewBox="-5 0 24 24" />
        </IconButton>
      )}
      <Box ref={containerRef} className={classes.scrollContainer}>
        {renderContent()}
      </Box>
      {canScrollNext && (
        <IconButton
          onClick={scrollNext}
          className={`${classes.iconButton} ${classes.nextButton}`}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default RecommendedActionContainer;
